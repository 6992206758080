import React, {  useContext, useEffect } from 'react';
import { useVideoTexture, useTexture } from '@react-three/drei';
import { AppContext } from '../context/AppContext';

export const url = '/video/LFES-60-Launch.mp4';
export const fallbackURL = '/textures/lobby/TV_Spot_Thumbnail.jpg';

export const VideoMaterial = React.memo(() => {
    const { videoRef, loadVideo, activeProduct } = useContext(AppContext);
    const videoTexture = useVideoTexture(loadVideo ? '/video/LFES-60-Launch.mp4' : null);
    const fallbackTexture = useTexture(fallbackURL);
    
    useEffect(() => {
        if (videoTexture != null && videoTexture.image instanceof HTMLVideoElement) {
            videoTexture.image.muted = false
            videoTexture.image.currentTime = 0;
            // Store the video element in the ref
            videoRef.current = videoTexture.image;
        }
    }, [videoTexture]);

    if (loadVideo && videoTexture) {
        return <meshBasicMaterial map={videoTexture} toneMapped={false} transparent opacity={activeProduct === 'tv' ? 1 : 0.25} />
    } else {
        return <meshBasicMaterial map={fallbackTexture} toneMapped={false} muted={false} />
    }
});

export function FallbackMaterial() {
    const fallbackTexture = useTexture(fallbackURL)
    return <meshBasicMaterial map={fallbackTexture} toneMapped={false} muted={false} />
}

export function TVController() {
    const { 
        videoRef,
        setLoadVideo,
        activeProduct,
        setActiveProduct,
        setMerchActive,
        animating,
   } = useContext(AppContext);
    
    function handlePlayClick() {
      if (videoRef.current) {
          videoRef.current.play();
      }
    }
  
    function handlePauseClick() {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    }
  
    function handleCloseButton() {
        handlePauseClick();
        animating.current = false;
        setActiveProduct(null);
        setMerchActive(false);
        setLoadVideo(false)
    }
    return (
      <div id="tv-controller" className={activeProduct === 'tv' ? 'show' : 'hide'}> 
            <button
            aria-label='Play the Video'
            className="btn play-video-button" 
            onClick={handlePlayClick}><span className='sr-only'>Play</span></button>
            <button
            aria-label="Pause the Video"
            className="btn pause-video-button" 
            onClick={handlePauseClick}><span className='sr-only'>PAUSE</span></button>
          <button
          aria-label="Close the Video"
          className="btn close-video-button"
          onClick={handleCloseButton} />
      </div>
    )
  }