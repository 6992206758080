import React, { useState } from 'react';
import { AppContext } from '../context/AppContext'

export default function FAQs() {
  const { areas, products, surprises } = React.useContext(AppContext);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      setActiveIndex(index);
    }
  };

  const faqs = [
    {
      question: 'How can I update my account information?',
      answer: (
        <p>Once logged in, click on the Account Icon. From here, you can update your account information under “Account Details”</p>
      ),
    },
    {
      question: 'Can I ship my order to a PO Box?',
      answer: (
        <p>Yes. To ship to a PO Box, please select “USPS SmartPost” as your shipping option at checkout. Please note Fedex and UPS will NOT accommodate shipping to a PO Box.</p>
      ),
    },
    {
      question: 'When will I get tracking information?',
      answer: (
        <p>You'll receive an email with your tracking info as soon as your package ships. Please note it can sometimes take up to 3 days for your tracking link to update.</p>
      ),
    },
    {
      question: 'How long will it take to receive my order once placed?',
      answer: (
        <p>Expect your items to ship within 2-4 weeks. Should you have any additional questions regarding shipping or order status, please contact us at <a href="mailto: customerservice@iceboxmail.com" style={{fontSize: '11px', color: "black"}}>customerservice@iceboxmail.com</a></p>),
    },
    {
      question: 'Can I cancel my order?',
      answer: (
        <p>Unfortunately, we can NOT change any information on an existing order once it has been processed. We will charge your card upon check out and you will not be able to change your order once you click the “Submit” button. Should you receive your merchandise and decide you'd like to make a return, please follow the “Return Instructions.”</p>
      ),
    },
    {
      question: 'How do I make a return?',
      answer: (
        <>
          <p>If for any reason you are dissatisfied with your purchase, simply return the merchandise within 30 days of the date of your purchase for an exchange or refund.</p> 
          <p>
            To Make Your Return: 
          </p>
            <ul>
              <li>
                Contact us at <a href="mailto: customerservice@iceboxmail.com" style={{fontSize: '11px', color: "black"}}>customerservice@iceboxmail.com</a> with your order number and reason for return.
              </li> 
              <li>
                Upon approval for return, please ship your return item in the original package with your sales receipt.
              </li> 
              <li>
                Please send the package prepaid and insured to us.
              </li> 
              <li>
                We do not accept C.O.D. deliveries.
              </li>
            </ul>
          <p>
            Please note the following limitations and exclusions: 
          </p>
            <ul>
              <li>
                You must have a valid sales receipt in order to receive a refund or credit.
              </li>
              <li> 
                If you do not have a valid receipt, we are unable to make a return or exchange of product.
              </li>
              <li>
                Returned merchandise must be unwashed, unworn and not damaged after you received it from us.
              </li>
            </ul>
          <p>
            If you request and are eligible for a refund, we will refund the price actually paid by you for the returned item. Shipping and handling costs are non-refundable unless we have sent the wrong item, or the item sent is defective or damaged at the time of delivery.
          </p>
        </>
      ),
    },
    {
      question: 'I have a question but don\'t see it answered here. What should I do?',
      answer: (
        <>
          <p>
            Just contact our team by email at <a href="mailto: customerservice@iceboxmail.com" style={{fontSize: '11px', color: "black"}}>customerservice@iceboxmail.com</a> or call us at <a href="tel:+014046652440" style={{color: "black"}}>404.665.2440</a>. We're available Monday through Friday from 8:30 AM to 5:30 PM EST.
          </p>
        </>
      ),
    }
  ];
  return (
    <div className='faq-container'>
      <div className='faqs-content'>
        {faqs.map((faq, index) => (
          <div className={activeIndex === index ? 'faq expanded' : 'faq'} key={index}>
            <h3 
              className='question' 
              onClick={() => setActiveIndex(index)}
              onKeyDown={(event) => handleKeyPress(event, index)}
              aria-expanded={activeIndex === index}
              role="button"
              tabIndex={index + areas.length + products.length + surprises.length + 1}
              aria-label="Question"
            >
              {faq.question}
            </h3>
            {activeIndex === index && <div className='answer' aria-label="Answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  )
}