
export default function StaticBrandbar() {
  return (
    <div id="brandbar" className="static-brandbar">
      <a href="/">
        <img className="brand-logo"
        alt="SONIC"
        width="300"
        src="/ui/sonic-logo.svg" />
      </a>
    </div>
  );
}