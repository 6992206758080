import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/AppContext';

export default function Helper() {

  const { 
    isFullscreen,
    areas,
    hoveredArea,
    areaActive,
    merchActive
   } = useContext(AppContext);

  // Find the corresponding area object from the areas array
  const currentArea = areas.find(area => area.id === hoveredArea);

  return (
    isFullscreen && !areaActive && !merchActive && (
      <div id="helper" className={hoveredArea === "gear" || hoveredArea === "goods" || hoveredArea === "garage" ? 'show' : 'hide'}>
        {currentArea ? currentArea.desc : ''}
      </div>
    )
  )
}