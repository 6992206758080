import React from 'react';
import { AppContext } from '../context/AppContext'


export default function Overlay() {

  const { isFullscreen, setIsFullscreen } = React.useContext(AppContext)

  // enter experience
  const handleEnterButtonClick = () => {

    document.body.style.overflowY = 'hidden';


    setTimeout(() => {
      setIsFullscreen(true)
    }, 550); // delay in milliseconds
  }
  return (
    <>
      <div id="html-content" className={isFullscreen ? 'hide-overlay' : 'show-overlay'}>
        <div id="overlay">
          <div id="overlay-content">
            <div className="overlay-content-inner">
              <img
                className="headline"
                src="../ui/LiveFreeShopSONIC.svg"
                alt="LIVE FREE SHOP SONIC"
                width="900"
              />
              <h1>ENJOY SHOPPING THE DEPARTMENT OF RESEARCH AND DELICIOUSMENT</h1>
            </div>
            <div className='fancy'>
              <button id="enter-button" onClick={handleEnterButtonClick} aria-label="Enter 3D Portal to Sonic's R&D Department" tabIndex={1}>ENTER</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
