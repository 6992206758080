export default function Footer() {
  return (
    <>
      <div id="footer-content">
        <div className="footer-container white-bkg">
          <div className="footer-content">
            <div>
              <p>Follow us to see the latest from SONIC</p>
              <div className="social-icons">
                <a href="https://www.instagram.com/sonicdrivein/" target="_blank"><img src="/ui/instagram.svg" alt="Instagram" width="24" height="24" /></a>
                <a href="https://www.facebook.com/Sonic.Drive.In.6823" target="_blank"><img src="/ui/facebook.svg" alt="Facebook" width="24" height="24" /></a>
                <a href="https://www.youtube.com/user/sonicdrivein" target="_blank"><img src="/ui/youtube.svg" alt="YouTube" width="24" height="24" /></a>
                <a href="https://twitter.com/sonicdrivein" target="_blank"><img src="/ui/twitterx.svg" alt="TwitterX" width="24" height="24" /></a>
              </div>
            </div>
            <p><a href="https://www.sonicdrivein.com/privacy-policy/" target="_blank">Privacy Policy</a></p>
            <p>TM & &copy;2024 America's Drive-In Brand Properties LLC v.1.332</p>
          </div>
        </div>
      </div>
    </>
  );
}
