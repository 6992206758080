import { gsap } from 'gsap';

const handleCloseBookInteraction = (
    event, 
    floorLogoMesh,
    bookCoverMesh,
    bookEndMesh,
    closeBookMesh,
    ) => {
    if (event) {
        event.stopPropagation();
    }

    gsap.killTweensOf('.popup-container');
    gsap.killTweensOf(bookCoverMesh.rotation);
    gsap.killTweensOf(bookCoverMesh.position);
    gsap.killTweensOf(bookEndMesh.position);
    gsap.killTweensOf(floorLogoMesh.position);
    gsap.killTweensOf(closeBookMesh.position);
    gsap.to(bookCoverMesh.rotation, { duration: 0.5, y: 0, ease: 'circ.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookEndMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(bookEndMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(floorLogoMesh.position, { duration: 0.8, y: 0.229, ease: 'expo.inOut' });
    gsap.to(closeBookMesh.position, { duration: 0.8, y: -38, ease: 'expo.inOut' });
    gsap.to('.popup-container', { duration: 0, x:-700, y: 0 })
};

export default handleCloseBookInteraction;