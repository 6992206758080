import { gsap } from 'gsap';
import * as THREE from 'three';

const handleAreaInteraction = (
    event, 
    raycaster, mouse, camera, 
    areas, 
    setActiveProduct, 
    floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
    targetPositionCamera, 
    lookAtPosition, 
    isMobile, 
    animating, 
    setMerchActive, 
    setAreaActive,
    setHoveredArea
) => {
    if (event) {
        event.stopPropagation();
    }

    // Get the 3D objects associated with the areas
    const areaObjects = areas.map(area => area.target.current);

    // Filter out any undefined objects
    const validAreaObjects = areaObjects.filter(object => object !== undefined);

    gsap.killTweensOf('.popup-container');
    gsap.killTweensOf(bookCoverMesh.rotation);
    gsap.killTweensOf(bookCoverMesh.position);
    gsap.killTweensOf(bookEndMesh.position);
    gsap.killTweensOf(floorLogoMesh.position);
    gsap.to(bookCoverMesh.rotation, { duration: 0.5, y: 0, ease: 'circ.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookEndMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(bookEndMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(floorLogoMesh.position, { duration: 0.8, y: 0.229, ease: 'expo.inOut' });
    gsap.to(closeBookMesh.position, { duration: 0.8, y: -38, ease: 'expo.inOut' });

    if (validAreaObjects.length > 0) {
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(validAreaObjects);

        if (intersects.length > 0) {
            // Handle hover
            const intersectedArea = areas.find(area => area.target.current === intersects[0].object);
            if (intersectedArea) {
                if (isMobile) {
                    setHoveredArea(intersectedArea.id);
                    setAreaActive(false)
                }
                setActiveProduct(intersectedArea.id);
                if (intersectedArea.id === 'deals') {
                    gsap.to('.popup-container', { duration: 1, x: 0, y:0, ease: 'expo.inOut', delay: 0.25 })
                } else {
                    if (isMobile) {
                        gsap.to('.popup-container', { duration: 0.5, y: 700 }); 
                    } else {
                        gsap.to('.popup-container', { duration: 0.5, x: -700 });
                    }
                }
                if (!isMobile) {
                    targetPositionCamera.current.copy(new THREE.Vector3(...intersectedArea.camera));
                    lookAtPosition.current.copy(new THREE.Vector3(...intersectedArea.position).add(new THREE.Vector3(0, intersectedArea.label === 'AREA' ? -10 : (intersectedArea.label === 'GEAR' ? -6 :  0), 0)));
                    gsap.to(camera.position, {
                        duration: 1,
                        x: targetPositionCamera.current.x,
                        y: targetPositionCamera.current.y,
                        z: targetPositionCamera.current.z, 
                        onUpdate: () => {
                            // Update the ref to the current camera position during the animation
                            camera.updateProjectionMatrix();
                            targetPositionCamera.current.copy(camera.position);
                        }
                    });
                }
                
            }
        } else {
            // 
            setActiveProduct(null);
        }
        
        if (!isMobile) { 
            setAreaActive(true);
            animating.current = true;
        }
        setMerchActive(false)
    }

};

export default handleAreaInteraction;