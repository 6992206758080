export default function SweepsCallout() {
  return (
    <>
     <div id="comingsoon-content">
      <div className="static-container flex-center">
        <img style={{maxWidth: '850px', width: '100%', marginBottom: '2rem'}} src='./fun99/headline.svg' width="1024" alt="Fun Is On The Menu - SWEEPSTAKES Powered by TripAdvisor" />
        <p style={{maxWidth: '600px'}}>If you're not livin' free, you're not having fun. And we want to give you the most fun trip on us.</p>
        <a href="/fun99" className="shopit-button">ENTER TO WIN</a>
      </div>
    </div>
    </>
  );
}
