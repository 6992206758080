import * as THREE from 'three';
import { extend } from '@react-three/fiber';

const GlowMaterial = new THREE.ShaderMaterial({
  uniforms: {
    viewVector: { type: 'v3', value: new THREE.Vector3() },
    uGlowFactor: { type: 'f', value: 0.01 },
    uOpacity: { type: 'f', value: 0.5 }
  },
  vertexShader: /*glsl*/`
    uniform vec3 viewVector;
    varying float intensity;
    void main() {
      gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4( position, 1.0 );
      vec3 actual_normal = vec3(modelMatrix * vec4(normal, 0.0));
      intensity = pow( dot(normalize(viewVector), actual_normal), 2.0 );
    }
  `,
  fragmentShader: /*glsl*/`
    varying float intensity;
    uniform float uGlowFactor;
    uniform float uOpacity;
    void main() {
      vec3 glow = vec3(0.9, 0.3, 0.3) * intensity;
      gl_FragColor = vec4( glow, uGlowFactor * uOpacity ); 
    }
  `,
  side: THREE.BackSide,
  blending: THREE.AdditiveBlending,
  transparent: true,

  depthWrite: false
});

extend({ GlowMaterial });

export default GlowMaterial;