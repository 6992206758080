import { gsap } from 'gsap';
import * as THREE from 'three';

const handleSurpriseInteraction = (
    event, 
    raycaster, mouse, camera, 
    surprises,
    activeProduct, setActiveProduct,
    videoRef,
    setLoadVideo,
    floorLogoMesh,
    bookCoverMesh,
    bookEndMesh,
    closeBookMesh,
    targetPositionCamera,
    lookAtPosition,
    isMobile,
    animating,
    setMerchActive,
    setAreaActive
) => {

    if (event) {
        event.stopPropagation();
    }

    // Get the 3D objects associated with the surprises
    const surpriseObjects = surprises.map(Surprise => Surprise.target.current);

    // Filter out any undefined objects
    const validSurpriseObjects = surpriseObjects.filter(object => object !== undefined);

    if (validSurpriseObjects.length > 0) {
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(validSurpriseObjects);

        if (intersects.length > 0) {
            // Handle inner click
            const intersectedSurprise = surprises.find(Surprise => Surprise.target.current === intersects[0].object);
            if (intersectedSurprise) {
                if (activeProduct === 'kiosk' || activeProduct === 'deals') {
                    // Open the URL in a new tab
                    window.open(intersectedSurprise.url, '_blank');
                }
                if (activeProduct === 'trip') {
                    // Open the URL
                    window.open(intersectedSurprise.url);
                }
            }
            setActiveProduct(intersectedSurprise.id);
            gsap.killTweensOf('.popup-container');
            gsap.killTweensOf(bookCoverMesh.rotation);
            gsap.killTweensOf(bookCoverMesh.position);
            gsap.killTweensOf(bookEndMesh.position);
            gsap.killTweensOf(floorLogoMesh.position);
            gsap.killTweensOf(closeBookMesh.position);
            
            if (intersectedSurprise.id === 'tv') {
                if (isMobile) {
                    gsap.to('.popup-container', { duration: 0, y: 700 }); 
                } else {
                    gsap.to('.popup-container', { duration: 0, x: -700 });
                }
                if (videoRef.current) {
                    videoRef.current.play();
                }
                setLoadVideo(true)
            }
            else if (intersectedSurprise.id === 'book') {
                if (isMobile) {
                    gsap.to('.popup-container', { duration: 0, y: 700 }); 
                } else {
                    gsap.to('.popup-container', { duration: 0, x: -700 });
                }
                gsap.to(floorLogoMesh.position, { duration: 3, y: 7.5, ease: 'expo.inOut' });
                gsap.to(bookCoverMesh.position, { duration: 5, y: 3.5, delay: 1.25, ease: 'expo.out' });
                gsap.to(bookEndMesh.position, { duration: 5, y: 3.5, delay: 1.25, ease: 'expo.out' });
                gsap.to(bookCoverMesh.rotation, { duration: 3, y: -2.5, delay: 2, ease: 'circ.inOut' });
                gsap.to(bookCoverMesh.position, { duration: 3.2, z: 0.35, delay: 1.8, ease: 'expo.inOut' });
                gsap.to(bookEndMesh.position, { duration: 3, z: 0.5, delay: 2, ease: 'expo.inOut' });
                gsap.to(closeBookMesh.position, { duration: 3, y: -11, ease: 'expo.inOut' });
            }
            else {
                gsap.to('.popup-container', { duration: 1, x:0, y: 0, ease: 'expo.inOut', delay: 0.25 })
            }
            if (!isMobile) { 
                targetPositionCamera.current.copy(new THREE.Vector3(...intersectedSurprise.camera));
                lookAtPosition.current.copy(new THREE.Vector3(...intersectedSurprise.position).add(new THREE.Vector3(0, intersectedSurprise.label === 'BOOK' ? 15 : 0), 0));
                gsap.to(camera.position, {
                    duration: 1,
                    x: targetPositionCamera.current.x,
                    y: targetPositionCamera.current.y,
                    z: targetPositionCamera.current.z,
                    onUpdate: () => {
                        // Update the ref to the current camera position during the animation
                        camera.updateProjectionMatrix();
                        targetPositionCamera.current.copy(camera.position);
                    }
                });
                animating.current = true;
            }
        } else {
            // 
            setActiveProduct(null);
        }
        // PopupOpenState
        if(!isMobile) {animating.current = true}
        setMerchActive(true)
        setAreaActive(false)
    }

};

export default handleSurpriseInteraction;