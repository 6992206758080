import React, { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { Sphere } from '@react-three/drei';
import { Color } from 'three';

function Rock({ color }) {
  const mesh = useRef();
  const speed = useMemo(() => Math.random() * 0.3 + 0.05, []); // Random speed for each rock
  const maxRadius = 4.4; // Maximum radius of the circular path
  const initialPhase = useMemo(() => Math.random() * 2 * Math.PI, []); // Random initial phase for each rock
  const verticalPosition = useMemo(() => Math.random() * 11 - 1, []); // Random vertical position for each rock

  useFrame((state) => {
    if (mesh.current) {
      // mesh.current.rotation.x += speed;
      // mesh.current.rotation.y += speed;
      // Calculate the current radius based on the elapsed time and initial phase
      const radius = maxRadius * (1 + Math.sin(state.clock.getElapsedTime() * speed + initialPhase)) / 2;
      mesh.current.position.x = radius * Math.cos(state.clock.getElapsedTime() * speed + initialPhase);
      mesh.current.position.z = radius * Math.sin(state.clock.getElapsedTime() * speed + initialPhase);
      mesh.current.position.y = verticalPosition * Math.sin(state.clock.getElapsedTime() * speed / 3);
    }
  });

  return (
    <Sphere ref={mesh} args={[0.25, 6, 6]}>
      <meshStandardMaterial color={color} />
    </Sphere>
  );
}

export default function Aquarium() {
  const rocks = new Array(64).fill(null); // Create 24 rocks

  return (
    <group position={[-20.25, -15.5, -16.25]}>
      {rocks.map((_, i) => (
        <Rock key={i} color={new Color(`hsl(${(i / rocks.length) * 360}, 100%, 50%)`)} />
      ))}
    </group>
  );
}