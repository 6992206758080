import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/AppContext';

export default function Instructions() {
  const [visible, setVisible] = useState(true);

  const { isFullscreen, isMobile, activeProduct } = useContext(AppContext);


  return (
    <div id="instructions" className={isFullscreen && visible && activeProduct === null ? 'show' : 'hide'}>
      {isMobile ? 'Hold and drag to look around, use two fingers to zoom in and out and pan around. Tap on items to explore more and shop!' : 'Move your cursor to explore, and click on items to explore more and shop!'}
      <button
        aria-label="Close the Instructions"
        className="btn close-button"
        onClick={(event) => {
          event.stopPropagation();
          setVisible(false);
        }} />
    </div>
  )
}