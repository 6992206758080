import * as THREE from 'three';
import { useMemo } from 'react';

function useCupCausticsMaterial() {
  const material = useMemo(() => new THREE.ShaderMaterial({
    uniforms: {
      uTime: { value: 0 },
      uResolution: { value: new THREE.Vector2() },
    },
    vertexShader: /*glsl*/`
      varying vec2 vUv;

      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: /*glsl*/`
      uniform float uTime;
      uniform vec2 uResolution;

      #define TAU 6.28318530718
      #define MAX_ITER 5
      #define SCALE 0.8

      varying vec2 vUv;

      void main() {
        float time = uTime * 0.6+23.0;
        vec2 uv = vUv * SCALE;
        vec2 p = mod(uv*TAU, TAU)-250.0;
        vec2 i = vec2(p);
        float c = 1.0;
        float inten = .004;

        for (int n = 0; n < MAX_ITER; n++) {
          float t = time * (1.0 - (3.5 / float(n+1)));
          i = p + vec2(cos(t - i.x) + sin(t + i.y), sin(t - i.y) + cos(t + i.x));
          c += 1.0/length(vec2(p.x / (sin(i.x+t)/inten),p.y / (cos(i.y+t)/inten)));
        }
        c /= float(MAX_ITER);
        c = 1.17-pow(c, 1.4);
        vec3 colour = vec3(pow(abs(c), 6.0));
        colour = clamp(colour + vec3(0.0, 0.3, 0.6), 0.0, 1.0);

        vec3 finalColor = colour;
        gl_FragColor = vec4(finalColor, 0.8);
      }
    `,
    transparent: true,
  }), []);

  return material;
}

export default useCupCausticsMaterial;