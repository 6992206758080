import React, { useRef, useMemo, useState } from 'react';
import { Physics, useBox } from '@react-three/cannon';
import { useFrame } from '@react-three/fiber';
import { Sphere } from '@react-three/drei';

function Ice({ color }) {
  const mesh = useRef();
  const speed = useMemo(() => Math.random() * 0.015 + 0.01, []); // Random speed for each rock
  // Random x and z positions within a radius of 0.5
  const initialX = useMemo(() => (Math.random() - 0.5) / 1.5, []);
  const initialZ = useMemo(() => (Math.random() - 0.5) / 1.5, []);
  // Initial y position
  const initialY = useMemo(() => 5, []);
  // Random direction for the bounce
  const bounceDirection = useMemo(() => (Math.random() > 0.5 ? 1 : -1) * 0.5, []);
  const [isBouncing, setIsBouncing] = useState(false);
  const [bounceHeight, setBounceHeight] = useState(Math.random() * 2 - 6); // Random initial bounce height



  useFrame(() => {
    if (mesh.current) {
      // If the ice has reached the bottom and is not bouncing, make it bounce up and veer off to the side
      if (mesh.current.position.y <= -5 && !isBouncing) {
        setIsBouncing(true);
      }

      if (isBouncing) {
        mesh.current.position.y += speed; // Bounce up
        mesh.current.position.x += speed * bounceDirection; // Veer off to the side
        mesh.current.position.z += speed * bounceDirection; // Veer off to the side

        // If the ice has bounced high enough, reset its position to the top and stop the bounce
      if (mesh.current.position.y >= bounceHeight) {
        setIsBouncing(false);
        mesh.current.position.y = initialY;
        mesh.current.position.x = initialX; // Reset the x position as well
        setBounceHeight(Math.random() * 2 - 6); // Set a new random bounce height
      }
      } else {
        // Otherwise, move the ice down
        mesh.current.position.y -= speed;
      }

      // Set the z position
      mesh.current.position.z = initialZ;
    }
  });

  return (
    <Sphere ref={mesh} args={[0.015, 5, 5]}>
      <meshStandardMaterial color={color} />
    </Sphere>
  );
}

export default function IceMaker() {
  const rocks = new Array(64).fill(null); // Create 24 rocks

  return (
    <group position={[18.2, -18.5, -16.65]}>
      {rocks.map((_, i) => (
        <Ice key={i} color="white" />
      ))}
    </group>
  );
}