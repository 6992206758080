import * as THREE from 'three';
import { extend } from '@react-three/fiber';

const TargetMaterial = new THREE.ShaderMaterial({
  uniforms: {
    u_time: { value: 0 },
    u_color: { value: new THREE.Color(0xffffff) }
  },
  vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
  uniform float u_time;
  uniform vec3 u_color;
  varying vec2 vUv;
  void main() {
    float t = mod(u_time, 1.2);
    float radius = 0.4 * t; 
    float innerRadius = 0.35 * t; 
    float newInnerRadius = 0.08 * t; // Radius for the new inner circle
    float dist = distance(vUv, vec2(0.5, 0.5));
    float outerCircle = step(dist, radius);
    float innerCircle = step(dist, innerRadius);
    float newCircle = step(dist, newInnerRadius); // Step function for the new inner circle

    // Calculate alpha based on time, fading out after t > 0.6
    float fadeStart = 0.6;
    float alpha = (t > fadeStart) ? (1.2 - t) / (1.2 - fadeStart) : 1.0;

    // Define color for the new inner circle (solid white)
    vec4 whiteColor = vec4(1.0, 1.0, 1.0, 1.0); // RGBA for white

    // Radial gradient background
    float gradient = smoothstep(0.0, 0.5, dist);
    vec4 backgroundColor = mix(vec4(0.894,0.,0.275, 0.1), vec4(0.894,0.,0.275, 0.0), gradient);

    // Combine all elements
    vec4 color = mix(backgroundColor, vec4(u_color, alpha), outerCircle);
    color = mix(color, vec4(u_color, 0.0), innerCircle);
    color = mix(color, whiteColor, newCircle); // Apply the new inner circle

    gl_FragColor = color;
  }
`,
  side: THREE.DoubleSide,
  transparent: true,
  depthTest: false,
});

extend({ TargetMaterial });

export default TargetMaterial;