import SweepsCallout from './static/SweepsCallout'

export default function Static() {
  const scrollToContent = () => {
    const target = document.getElementById('callout-content');
    target.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className="flex-container">
        <img 
          className='scrolldown-arrow'
          src="/ui/double-arrow-down.svg" 
          aria-label="scroll down to explore more" 
          alt="Scroll down" 
          width="60"
          style={{ cursor: 'pointer', marginTop: '1rem' }} 
          onClick={scrollToContent}  />
      </div>
      <div id="callout-content">
        <SweepsCallout />
      </div>
    </>
  );
}